import React from 'react';
import './LeafAnimation.css';

function LeafAnimation() {
    return (
        <div id="leaves">
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
        </div>
    );
}

export default LeafAnimation;